import React from 'react';
import _ from 'lodash';

//import {Link, withPrefix, classNames} from '../utils';
//import Icon from './Icon';

export default class Mailchimp extends React.Component {
    render() {
        //let action = _.get(this.props, 'action', null);
        //let action_style = _.get(action, 'style', null) || 'link';
        //let action_icon_pos = _.get(action, 'icon_position', null) || 'right';
        return (
            <div
            dangerouslySetInnerHTML={{
                __html: `
                <style type="text/css">
                    #mc_embed_signup_scroll {
                        display: flex;
                        column-gap: 10px;
                        flex-wrap: wrap;
                        margin-top: -15px;
                    }
                    #mce-EMAIL {
                        height: 48px;
                        border-radius:3px;
                        color: #2c343c;
                        max-width: 270px;
                        margin-top: 15px;
                    }
                    #mc-embedded-subscribe {
                        color: white;
                        background: #01be54;
                        margin-top: 15px;
                    }

                    /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                    We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
                </style>
                <div id="mc_embed_signup">
                <form action="https://gmail.us7.list-manage.com/subscribe/post?u=16f209d7de4ee3887aaa0c72e&amp;id=86a8fa4f66" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                    <div id="mc_embed_signup_scroll">
                    
                    <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Email address" required>
                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_16f209d7de4ee3887aaa0c72e_86a8fa4f66" tabindex="-1" value=""></div>
                    <div class="clear"><input type="submit" value="Get Early Access" name="subscribe" id="mc-embedded-subscribe" class="btn btn--primary"></div>
                    </div>
                </form>
                </div>  
                `
            }}>
            </div>
           
            
        );
    }
}
