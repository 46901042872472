import React from 'react';
import _ from 'lodash';

import Action from './Action';
import Mailchimp from './Mailchimp';

export default class SectionActions extends React.Component {
    render() {
        let actions = _.get(this.props, 'actions', null);
        return (
            _.map(actions, (action, action_idx) => {
                if (action.type === 'Mailchimp') {
                    return <Mailchimp/>
                }
                return (
            	<Action key={action_idx} {...this.props} action={action} />
                )
            })
        );
    }
}
